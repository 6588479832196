import { useEffect } from "react";

import styles from "./styles.module.css";

export function PageWrapper({
  children = null,
  className = "",
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`${styles.wrapper} ${className}`} {...props}>
      {children}
    </div>
  );
}

export function PageTitle({
  children = null,
  className = "",
  html = false,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> & {
  children: React.ReactNode;
  html?: boolean;
  className?: string;
}) {
  useEffect(() => {
    if (!html || typeof children !== "string") return;
    document.title = children;
  }, [html, children]);
  return (
    <h1 className={`${styles.title} ${className}`} {...props}>
      {children}
    </h1>
  );
}
