import styles from "./styles.module.css";

import { PageWrapper, PageTitle } from "../../components/Page";

export default function HomePage() {
  return (
    <PageWrapper>
      <PageTitle html>Home</PageTitle>
    </PageWrapper>
  );
}
