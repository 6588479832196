import {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import useManager, { fakeManager } from "./useManager";
import { useLocation } from "react-router-dom";

import { createContext } from "react";

import type { Link } from "../types/link";
import type { Manager } from "./useManager";

import {
  Link as LinkComponent,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

interface User {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
}

interface UserContextValue {
  user: User | null;
  loading: boolean;
  loggedIn: boolean;
  tokenRef: React.MutableRefObject<string | null>;
  login: (email: string, password: string) => Promise<string | null>;
  links: Link[];
  linksManager: Manager<Link>;
}

const UserContext = createContext<UserContextValue>({
  user: null,
  loading: false,
  loggedIn: false,
  tokenRef: { current: null },
  login: async () => "Loading",
  links: [],
  linksManager: fakeManager<Link>([]),
});

function AwfulStyling() {
  return (
    <style>
      {`form {${Object.entries({
        display: "flex",
        "flex-flow": "column nowrap",
        width: "400px",
        padding: "1rem",
        background: "#f0f0f0",
        gap: "1rem",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      })
        .map(([key, value]) => `${key}: ${value};`)
        .join("\n")}}`}
    </style>
  );
}
function Login() {
  const { login } = useUser();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        login("", "");
        return false;
      }}
    >
      <AwfulStyling />
      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        placeholder="john@example.com"
        required
        autoComplete="email"
      />
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        placeholder="password"
        required
        autoComplete="current-password"
      />
      <button type="submit">Login</button>
      <LinkComponent to="/signup">Signup</LinkComponent>
    </form>
  );
}
function Signup() {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
    >
      <AwfulStyling />
      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        placeholder="john@example.com"
        required
        autoComplete="email"
      />
      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        placeholder="password"
        required
        autoComplete="new-password"
      />
      <button type="submit">Signup</button>
      <LinkComponent to="/login">Login</LinkComponent>
    </form>
  );
}

function RedirectToLogin() {
  const { pathname } = useLocation();
  return (
    <Navigate
      to={`/login?redirect=${encodeURIComponent(pathname.replace(/^\//, ""))}`}
      replace={true}
    />
  );
}

export function UserProvider({
  children = null,
}: {
  children: React.ReactNode;
}) {
  const [loading, setLoading] = useState(
    () => localStorage.getItem("auth") !== null
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const tokenRef = useRef<string | null>(localStorage.getItem("auth") ?? null);

  const [user, setUser] = useState<User | null>(null);
  const [links, linksManager] = useManager<Link>([]);

  const fetchUser = useCallback(async () => {
    const loggedIn = true;
    const user: User = {
      id: "1",
      name: "John Doe",
      email: "john@example.com",
      avatarUrl: "https://i.pravatar.cc/200?img=37",
    };
    const links: Link[] = [
      {
        id: "1",
        destination: "https://idiombrands.com",
        domain: "ti.ma",
        createdAt: 1727164109,
        active: true,
        visits: 23,
        slug: "i",
        label: "Website",
        tags: [],
      },
    ];

    setLoggedIn(loggedIn);
    setUser(user);
    setLoading(false);
    linksManager.setAll(links);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async (email: string, password: string) => {
    tokenRef.current = "token";
    localStorage.setItem("auth", "token");
    fetchUser();

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{ user, loading, loggedIn, tokenRef, login, links, linksManager }}
    >
      {loading ? (
        <div>loading</div>
      ) : !loggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<RedirectToLogin />} />
        </Routes>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}

export default function useUser(): UserContextValue {
  return useContext(UserContext);
}
