import { PageTitle, PageWrapper } from "../components/Page";

export function NotFoundPage() {
  return (
    <PageWrapper
      style={{ alignItems: "center", justifyContent: "center", flexGrow: 1 }}
    >
      <PageTitle html>404 Not Found</PageTitle>
    </PageWrapper>
  );
}
