import styles from "./styles.module.css";

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

export default function Header() {
  return (
    <header className={styles.root}>
      <Search />
      <Actions />
      <User />
    </header>
  );
}

function Search() {
  return (
    <button className={styles.search}>
      <SearchIcon /> Search or create link
    </button>
  );
}
function Actions() {
  return <button className={styles.action}>Create Link</button>;
}
function User() {
  return <button className={styles.user}>JD</button>;
}
