import { useEffect, useState } from "react";

import styles from "./styles.module.css";
import { createPortal } from "react-dom";

import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as SwitchIcon } from "../../assets/icons/switch.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/chart.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as CogIcon } from "../../assets/icons/cog.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { ReactComponent as QRIcon } from "../../assets/icons/qr.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";

export default function Sidebar() {
  const [animateLink, setAnimateLink] = useState(false);
  const { pathname } = useLocation();
  const [originalPathname] = useState(pathname);

  useEffect(() => {
    if (animateLink) return;
    if (originalPathname !== pathname) {
      setAnimateLink(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return createPortal(
    <aside className={`${styles.root} ${animateLink && styles.animation}`}>
      <header className={styles.header}>
        <Logo />
        tima
      </header>
      <Switcher />
      {false && <Search />}
      {false && <div className={styles.divider} />}
      <NavSection>
        <Link to="/">
          <HomeIcon />
          Overview
        </Link>
        <Link to="/settings">
          <CogIcon />
          Settings
        </Link>
      </NavSection>
      <NavSection
        title="Links"
        header={
          <button>
            <AddIcon />
          </button>
        }
      >
        <Link to="/links">
          <LinkIcon />
          All
        </Link>
        <Link to="/links/qr">
          <QRIcon />
          QR
        </Link>
      </NavSection>
      <NavSection title="Analytics">
        <Link to="/analytics">
          <ChartIcon />
          All
        </Link>
      </NavSection>
    </aside>,
    document.body
  );
}
function Link({
  to = "",
  children = "",
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `${styles.link} ${isActive && styles.active}`
      }
      end
    >
      {children}
    </NavLink>
  );
}
function NavSection({
  title = "",
  children = null,
  header = null,
}: {
  title?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
}) {
  return (
    <nav className={styles.nav}>
      {title !== "" && (
        <header className={styles.navHeader}>
          <div className={styles.title}>{title}</div>
          {header}
        </header>
      )}
      {children}
    </nav>
  );
}
function Switcher() {
  return (
    <button className={styles.switcher}>
      <div className={styles.current}>
        <picture style={{ backgroundImage: "url('/wearable.svg')" }} />
        Wearable
      </div>
      <SwitchIcon />
    </button>
  );
}
function Search() {
  return (
    <button className={styles.search}>
      <SearchIcon />
      Search or create link
    </button>
  );
}
