import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./hooks/useUser";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

import HomePage from "./pages/Home";
import SettingsPage from "./pages/Settings";

import { NotFoundPage } from "./pages/Misc";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <Sidebar />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
