import styles from "./styles.module.css";

import { PageTitle, PageWrapper } from "../../components/Page";

export default function SettingsPage() {
  return (
    <PageWrapper>
      <PageTitle html>Settings</PageTitle>
    </PageWrapper>
  );
}
